<template>
  <figure v-if="image" :class="classes" class="ingredient">
    <float
      :offset="floatOffset"
      :speed="floatSpeed"
      :reverse="floatReverse"
      class="ingredient__image"
    >
      <sanity-media
        :image="image"
        :image-src="imageSrc"
        :image-class="imageClass"
        :caption="titleNormalized"
        :swiper="swiper"
        max-width="480"
        max-height="480"
        contain
        fluid
      />
    </float>
    <figcaption v-if="titleNormalized" class="ingredient__text">
      <div class="flex flex-row items-center justify-center ingredient__title">
        <h4>
          {{ titleNormalized }}
        </h4>
      </div>
      <div
        v-if="descriptionText"
        class="ingredient__description ingredient__description--long"
      >
        {{ descriptionText }}
      </div>
    </figcaption>
  </figure>
</template>

<script>
import replaceAll from 'replaceall'
export default {
  name: 'Ingredient',

  props: {
    title: {
      default: null,
      type: String
    },
    image: {
      default: null,
      type: Object
    },
    imageSrc: {
      default: undefined,
      type: String
    },
    imageClass: {
      default: undefined,
      type: String
    },
    description: {
      default: undefined,
      type: String
    },
    longDescription: {
      default: undefined,
      type: String
    },
    floatOffset: {
      default: '-6%',
      type: String
    },
    floatSpeed: {
      default: 6,
      type: [Number, String]
    },
    swiper: {
      default: false,
      type: Boolean
    },
    floatReverse: {
      default: false,
      type: Boolean
    },
    useLongDescription: {
      default: false,
      type: Boolean
    },
    categoryImages: {
      type: Array,
      default: undefined
    }
  },

  computed: {
    classes() {
      return {
        'ingredient--long': this.useLongDescription
      }
    },
    descriptionText() {
      let text
      if (this.useLongDescription) {
        text = this.longDescription || this.description
      } else {
        text = this.description || this.longDescription
      }
      return text
    },
    titleNormalized() {
      // this replaces regular hyphens with non-breaking hyphens
      // the two characters below are NOT equivalent
      return replaceAll('-', '‑', this.title)
    }
  }
}
</script>

<style>
.ingredient__image {
  display: block;
  height: theme('height.win-h-1/4');
  margin-bottom: var(--y-gutter-sm);
  position: relative;
}

.ingredient__image .image .image__img {
  object-position: bottom center;
}

.ingredient__category-image {
  height: 32px;
  width: 32px;
}

.ingredient__title {
  font-weight: theme('fontWeight.medium');
  margin-bottom: 1.5em;
}

.ingredient__description {
  font-size: 0.9em;
  line-height: theme('lineHeight.tight');
}

.ingredient--long .ingredient__description {
  line-height: theme('lineHeight.snug');
}
</style>
