<template>
  <div v-if="isValid" class="ingredients-window px-x-gutter py-y-gutter">
    <window-frame>
      <template #window-top>
        <div class="p-1 book">
          {{ title }}
        </div>
      </template>
      <div :style="{ backgroundColor: bgHex }">
        <div class="heading heading--sm py-4 text-center">
          {{ entry.title }}
        </div>
        <ingredients class="py-2" :ingredients="entry.ingredients" />
      </div>
    </window-frame>
  </div>
</template>

<script>
import Ingredients from '~/components/shop/ingredients/ingredients.vue'

export default {
  name: 'IngredientsWindow',
  components: {
    Ingredients
  },
  props: {
    title: {
      type: String,
      required: true,
      default: undefined
    },
    entry: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  computed: {
    isValid() {
      // protecting from potentially missing CMS entry
      return this.entry && this.title && this.entry.ingredients?.length > 0
    },
    bgHex() {
      return this.entry?.backgroundColor?.hex
    }
  }
}
</script>

<style scoped>
.ingredients-window {
  margin: 0 auto;
  /* this is somewhat arbitrary but should prevent these boxes
  from getting too wide */
  max-width: 1200px;
}

.ingredients-window >>> .ingredient-row.container {
  margin-left: 24px;
  margin-right: 24px;
}
</style>
